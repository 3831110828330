const linkResolver = (doc, page) => {
  if (doc.type === "home_page") {
    return "/"
  }

  if (doc.type === "about_page") {
    return "/about"
  }

  if (doc.type === "articles_page") {
    return page ? `/articles/${page}` : `/articles/1`
  }

  if (doc.type === "article") {
    return `/articles/${doc.uid}`
  }

  if (doc.type === "journazine") {
    return `/journazines/${doc.uid}`
  }

  if (doc.type === "custom_page") {
    return `/${doc.uid}`
  }

  // Backup for all other types
  return "/"
}

module.exports = linkResolver
